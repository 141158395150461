<template>
  <div>
    <div class="header">
      <div class="header_top">
        <span style="margin: 0">当前位置：</span>
        <span class="center">批量合同</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">批量导入详情</span>
        <span class="clickColor" @click="goBack">返回</span>
      </div>
    </div>
    <el-card>
      <Divider :title="'基本信息'">
        <template slot="right">
          <span style="color:#aaaaaa;font-size: 12px;">创建时间：{{detailInfo.createTime}}</span>
        </template>
      </Divider>
      <el-descriptions style="margin:10px 0">
        <el-descriptions-item label="用工企业">{{detailInfo.workEterprise}}</el-descriptions-item>
        <el-descriptions-item label="企业区域">
          <span
            v-if="detailInfo.workEterpriseArea"
          >{{detailInfo.workEterpriseArea.provinceName}}-{{detailInfo.workEterpriseArea.cityName}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="企业门店">{{detailInfo.workEnterpriseStore}}</el-descriptions-item>
        <el-descriptions-item label="合同模板">{{detailInfo.template}}</el-descriptions-item>
        <el-descriptions-item label="合同类型">
          <span v-if="detailInfo.type==='1'">入职合同</span>
          <span v-if="detailInfo.type==='2'">离职合同</span>
          <span v-if="detailInfo.type==='3'">其他</span>
        </el-descriptions-item>
        <el-descriptions-item label="批量导入名称">{{detailInfo.name}}</el-descriptions-item>
        <el-descriptions-item label="甲方签署人">{{detailInfo.signer}}</el-descriptions-item>
        <el-descriptions-item label="批量导入描述">{{detailInfo.desc}}</el-descriptions-item>
      </el-descriptions>
      <Divider :title="'历史记录'"></Divider>
      <el-table :data="tableData" border style="width: 100%;margin:10px 0">
        <el-table-column v-for="(item, index) in List" :key="index" :label="item">
          <template slot-scope="scope">{{ scope.row[item] }}</template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const contract2ContractBatchInfo = api('')(
  'contract2.contractBatch.info.json'
);
export default {
  components: {
    Divider: () => import('../../../components/divider.vue'),
  },
  data () {
    return {
      code: '',
      detailInfo: {},
      tableData: [],
      List: []
    }
  },
  created () {
    this.code = this.$route.query.code;
    this.lodaing()
  },
  methods: {
    lodaing () {
      const params = {
        code: this.code,
      };
      contract2ContractBatchInfo(params).then(res => {

        this.detailInfo = res

        this.tableData = JSON.parse(res.datas)
        var obj = this.tableData[0];

        var reg = /^[\u4E00-\u9FA5]+$/;
        for (var key in obj) {
          if (reg.test(key)) {
            this.List.push(key);
          }
        }

      });
    },
    goBack () {
      window.history.go(-1);

    },
  },
}
</script>
<style lang="scss" scoped>
</style>